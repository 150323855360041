<template>
  <div>
    <Row class="m-b-10 ">
      <i-col
        span="24"
        class="m-b-10"
      >
        <h4 class="workplatform-title">
          {{
            planDetail.assetName +
            " " +
            planDetail.stationName +
            " " +
            planDetail.actionDate +
            " 作业详情"
          }}
        </h4>
      </i-col>
      <i-col span="12">
        <RadioGroup
          v-model="taskitemType"
          type="button"
          size="small"
          @on-change="handleChangeType"
        >
          <Radio
            v-for="item in tasktypes"
            :key="item.value"
            :label="item.value"
          >{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <Row
      :gutter="8"
      class="m-b-10"
    >
      <i-col span="3">
        <Select
          v-model="position"
          size="small"
          placeholder="含/不含轨行区"
          clearable
        >
          <Option
            v-for="item in arreaArray"
            :key="'area_'+item.value"
            :value="item.value"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <i-col span="3">
        <Select
          v-model="taskQuery.supplierId"
          size="small"
          placeholder="供应商"
          clearable
        >
          <Option
            v-for="item in companyArray"
            :key="'supplier_'+item.id"
            :value="item.id"
          >{{item.name}}</Option>
        </Select>
      </i-col>
      <!-- <i-col span="3">
        <Select
          size="small"
          v-model="taskQuery.taskitemStatus"
          placeholder="状态"
          clearable
        >
          <Option
            v-for="(item, index) in statusArray"
            :key="index"
            :value="item.id"
          >{{ item.name }}</Option>
        </Select>
      </i-col> -->
      <i-col span="4">
        <Input
          size="small"
          v-model="taskQuery.advertiserName"
          placeholder="客户名称"
        />
      </i-col>
      <i-col span="2">
        <Button
          type="primary"
          icon="ios-search"
          size="small"
          @click="searchData"
        >搜索</Button>
      </i-col>
      <i-col
        span="12"
        class="text-right"
      >
        <Button
          type="error"
          size="small"
          :disabled="selectedTaskItemIds.length===0"
          @click="handleDelay"
        >延期</Button>
      </i-col>
    </Row>
    <div>
      <Table
        size="small"
        stripe
        :data="taskList"
        :columns="tableColumns"
        @on-selection-change="selectionChange"
      ></Table>

      <div class="paging_style">
        <Page
          size="small"
          :total="total"
          :page-size="taskQuery.pageSize"
          show-total
          show-elevator
          show-sizer
          :page-size-opts="pagesizeSetting"
          @on-page-size-change='changePageSize'
          :current="taskQuery.pageNumber"
          @on-change="handlePageChanged"
        ></Page>
      </div>
    </div>
    <Modal
      v-model="visiblePreview"
      width="800"
      :footer-hide="true"
    >
      <p
        slot="header"
        class="text-center"
      >{{fileName}} {{variableTitle}}</p>
      <Row class="m-b-5">
        <i-col class="text-right">
          <Button
            size="small"
            type="success"
            @click="viewBigImage"
          >查看原图</Button>
        </i-col>
      </Row>
      <img-light-box
        ref="imgLightBox"
        :showModal="visiblePreview"
        :sortIndex.sync="sortIndex"
      ></img-light-box>
    </Modal>
    <Modal
      v-model="historyView"
      width="800"
      :footer-hide="true"
    >
      <p slot="header">任务变更历史</p>
      <Table
        stripe
        size="small"
        :data="historyList"
        :columns="historyColumns"
        :height="300"
        @on-selection-change="selectionChange"
      ></Table>
    </Modal>
  </div>
</template>
<script>
// import { GetDateStr } from '@/utils/dateFormat'
import { sysMixins } from '@/assets/mixins/sys'
import { companyMixin } from '@/assets/mixins/company'
import { commonMixins } from '@/assets/mixins/common'
import { getTaskitemType, getTaskitemPage, delay } from '@/api/msp/workday'
import { formatEnable } from '@/utils/tagStatus'
import ImgLightBox from '@/components/common/ImgVideoLightBox'

export default {
  mixins: [sysMixins, companyMixin, commonMixins],
  components: {
    ImgLightBox
  },
  data () {
    return {
      type: 3, // 获取供应商标识
      arreaArray: [
        { value: 0, name: '含' },
        { value: 1, name: '不含' }
      ],
      statusArray: [
        { id: 0, name: '派单中' },
        { id: 1, name: '已派单' },
        { id: 2, name: '执行中' },
        { id: 3, name: '已完成' }
      ],
      status: null,

      // currentPageAllItemIds: [], // 当前操作的页面所有项的主键集合

      tasktypes: [],
      taskitemType: 0,
      keyword: '',
      assetId: 0,
      assetArray: [],
      pagesizeSetting: [15, 50, 100, 200, 500],
      taskQuery: {
        pageNumber: 1,
        pageSize: 15,
        taskitemStatus: null,
        notContainDT_GXQ: null,
        supplierId: null,
        advertiserName: '',
        taskitemType: null
        // startDate: '',
        // endDate: ''
      },
      position: null,
      total: 0,
      tableColumns: [],
      tempSelectedTaskIds: [],
      selectedTaskItemIds: [],

      historyColumns: [
        {
          title: '变更项',
          align: 'center',
          key: 'typeName'
        },
        {
          title: '变更内容',
          align: 'center',
          key: 'remark'
        },
        { title: '操作人', align: 'center', key: 'userName' },
        { title: '操作时间', align: 'center', key: 'createTime' }

      ],
      historyList: [],

      taskTypeShowColumns: new Map([
        [1, ['selection', 'resourceCode', 'domainName', 'advertiserName', 'picturesource', 'supplierName', 'status', 'remark']], // 上刊设置
        [2, ['selection', 'resourceCode', 'domainName', 'actionName', 'supplierName', 'status', 'remark']], // 监播
        [3, ['selection', 'resourceCode', 'domainName', 'actionName', 'picturesource', 'supplierName', 'status']], // 制作
        [4, ['selection', 'resourceCode', 'domainName', 'supplierName', 'status', 'remark']], // 清洁任务
        [5, ['selection', 'resourceCode', 'domainName', 'supplierName', 'status', 'remark']], // 维修
        [6, ['selection', 'resourceCode', 'domainName', 'supplierName', 'status', 'remark']], // 测量任务
        [7, ['selection', 'resourceCode', 'domainName', 'advertiserName', 'actionFile', 'picturesource', 'supplierName', 'status', 'remark']], // 下刊
        [8, ['selection', 'domainName', 'supplierName', 'userName', 'status', 'remark']], // 巡检
        [9, ['selection', 'typeName', 'resourceCode', 'domainName', 'actionFile', 'supplierName', 'status', 'remark']], // 维护
        [10, ['selection', 'resourceCode', 'domainName', 'actionFile', 'picturesource', 'supplierName', 'status', 'remark']], // 临时任务
        [11, ['selection', 'resourceCode', 'supplierName', 'status', 'remark']] // 填充上刊任务
      ]),
      taskList: [],
      currentDate: '全部',
      visiblePreview: false,
      sortIndex: 0,
      fileName: '',
      variableTitle: '',
      historyView: false
    }
  },
  created () {
    this.$store.commit('set_board_selectedTaskItemIds', [])
    this.initData()
  },
  computed: {
    planDetail () {
      return this.$store.state.board.planDetail
    }
  },
  mounted () {
    this.tableColumns = this.getTableColumns()
  },
  methods: {
    initData () {
      // 获取左侧品牌作业计划数据
      this.$store.dispatch('getBrandSummaryData', { startDate: this.planDetail.actionDate, endDate: this.planDetail.actionDate, stationId: this.planDetail.stationId })
      // 获取右侧统计数据
      this.$store.dispatch('getWorkTypeData', { startDate: this.planDetail.actionDate, endDate: this.planDetail.actionDate, stationId: this.planDetail.stationId })
      this.$store.dispatch('getSupplierStatData', { startDate: this.planDetail.actionDate, endDate: this.planDetail.actionDate, stationId: this.planDetail.stationId })
      // 获取中间部分数据
      this.geTasktypes()
      this.getCompanyArray()
    },
    // 加载任务类型
    geTasktypes () {
      const query = {
        stationId: this.planDetail.stationId,
        workday: this.planDetail.actionDate
      }
      getTaskitemType(query).then(res => {
        if (res && !res.errcode) {
          this.tasktypes = res
          this.taskitemType = this.tasktypes[0].value
          this.initplandetailPage()
        } else {
          this.tasktypes = []
          this.taskitemType = 0
        }
      })
    },
    getTableColumns () {
      const tableColumnArray = {
        selection: { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        resourceCode: { title: this.taskType === 11 ? '任务名称' : '资源编号', key: 'resourceCode', minWidth: 100, align: 'center' },
        assetName: { title: '所属资产', key: 'assetName', minWidth: 100, align: 'center' },
        domainName: { title: '站点', key: 'stationName', minWidth: 100, align: 'center' },
        advertiserName: { title: '客户信息', key: 'advertiserName', minWidth: 100, align: 'center', ellipsis: true, tooltip: true },
        typeName: { title: '任务类型', key: 'typeName', minWidth: 100, align: 'center' },
        // finishDate: { title: '当前在刊结束日', key: 'finishDate', minWidth: 100, align: 'center' },
        // actionDate: { title: '作业日期', key: 'actionDate', minWidth: 100, align: 'center' },
        actionFile: {
          title: '作业画面',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            if (params.row.taskitemFileList && params.row.taskitemFileList.length > 0) {
              if (params.row.taskitemFileList.some(x => (x.mimeType.includes('video') || x.mimeType.includes('audio')))) {
                return h('a', {
                  on: {
                    click: () => {
                      const postData = {
                        startIndex: 0,
                        imgList: params.row.taskitemFileList
                      }
                      this.sortIndex = 0
                      this.fileName = params.row.stationName
                      this.$refs.imgLightBox.init(postData)
                      this.visiblePreview = true
                    }
                  },
                  style: {
                    cursor: 'pointer'
                  }
                }, '查看素材')
              } else {
                return h('div', [
                  h(
                    'img',
                    {
                      domProps: {
                        src: params.row.taskitemFileList[0].fileKey + '?x-oss-process=image/resize,w_64'
                      },
                      on: {
                        click: () => {
                          const postData = {
                            startIndex: 0,
                            imgList: params.row.taskitemFileList
                          }
                          this.sortIndex = 0
                          this.fileName = params.row.domainName
                          this.variableTitle = ''
                          this.curShowFileArray = params.row.taskitemFiles
                          this.$refs.imgLightBox.init(postData)
                          this.visiblePreview = true
                        }
                      },
                      style: {
                        width: '64px',
                        cursor: 'pointer'
                      }
                    }
                  )
                ])
              }
            } else {
              return h('span', '暂无画面')
            }
          }
        },
        actionName: {
          title: '作业名称',
          minWidth: 100,
          align: 'center',
          render: (h, params) => {
            return h('span', Array.from(new Set(params.row.taskitemFileList.map(x => x.fileName))))
          }
        },
        picturesource: {
          title: '画面来源',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('div', [
              h('p', params.row.picturesourceName),
              params.row.isReuseOldFile ? h('p', {
                style: { color: '#ff9900' }
              }, '使用已有画面') : null
            ])
          }
        },
        existProduceFile: {
          title: '已有制作素材',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return formatEnable(h, params.row.existProduceFile)
          }
        },
        supplierName: {
          title: '施工负责人',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            return h('span', params.row.executeUserName)
          }
        },
        userName: {
          title: '巡检人员',
          align: 'center',
          minWidth: 60,
          render: (h, params) => {
            return h('span', params.row.taskitemLabor.userName)
          }
        },
        status: {
          title: '状态',
          key: 'status',
          minWidth: 80,
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.status ? '延期' : '未延期')
          }
        },
        remark: {
          title: '备注',
          align: 'center',
          minWidth: 100,
          render: (h, params) => {
            const remark = `${params.row.remark}`
            return h('Tooltip', {
              props: {
                content: remark,
                transfer: true,
                'max-width': '200px'
              }
            }, remark.length > 12 ? remark.substr(0, 11) + '...' : remark)
          }
        }
        // operate: {
        //   title: '操作',
        //   align: 'center',
        //   minWidth: 100,
        //   render: (h, params) => {
        //     var html = []
        //     html.push(h('a', {
        //       style: { marginRight: '5px', cursor: 'pointer' },
        //       on: {
        //         click: () => {
        //           const that = this
        //           const param = {
        //             taskitemId: params.row.id
        //           }
        //           gettaskitemhistorylist(param).then(res => {
        //             that.historyList = res
        //             that.historyView = true
        //           })
        //         }
        //       }
        //     }, '变更历史'))

        //     return h('div', html)
        //   }
        // }
      }

      const data = []
      var showColumns = this.taskTypeShowColumns.get(this.taskType)
      if (!showColumns || showColumns.length === 0) {
        showColumns = this.taskTypeShowColumns.get(1)
      }
      showColumns.forEach(col => data.push(tableColumnArray[col]))

      return data
    },
    initplandetailPage () {
      const that = this
      that.taskQuery.notContainDT_GXQ = that.position ? Boolean(that.position) : null
      if (that.planDetail) {
        // that.taskQuery.actionDate = that.planDetail.actionDate
        that.taskQuery.stationId = that.planDetail.stationId
        that.taskQuery.taskitemType = that.taskitemType
        getTaskitemPage(that.taskQuery).then(res => {
          if (res && !res.errcode) {
            if (res.list.length) {
              res.list.forEach(item => {
                item._checked = that.selectedTaskItemIds.includes(item.id)
                item._disabled = item.status
              })
              // that.currentPageAllItemIds = res.list.map(item => {
              //   return item.id
              // })
            }
            that.taskList = res.list
            that.total = res.totalRow
          } else {
            that.taskList = []
            that.total = 0
          }
        })
      }
    },
    handleChangeType () {
      this.selectedTaskItemIds = []
      this.taskQuery.pageNumber = 1
      this.initplandetailPage()
    },
    changePageSize (pageSize) {
      this.taskQuery.pageSize = pageSize
      this.taskQuery.pageNumber = 1
      this.selectedTaskItemIds = []
      this.initplandetailPage()
    },
    handlePageChanged (page) {
      this.taskQuery.pageNumber = page
      this.initplandetailPage()
    },
    searchData () {
      this.selectedTaskItemIds = []
      this.taskQuery.pageNumber = 1
      this.initplandetailPage()
    },
    selectionChange (selection) {
      this.selectedTaskItemIds = []

      // 最终选中的所有taskItem
      const curSelection = selection.map(x => x.id)
      this.handelPageSelections(this.tempSelectedTaskIds, curSelection, this.taskQuery.pageNumber)
      this.tempSelectedTaskIds.forEach(item => {
        this.selectedTaskItemIds = this.selectedTaskItemIds.concat(item.existSelections)
      })
    },
    viewBigImage () {
      this.$refs.imgLightBox.viewBigImg()
    },
    /**
     * 延期
     */
    handleDelay () {
      this.$Modal.confirm({
        title: '操作提示',
        content: '确定要进行延期操作？',
        onOk: () => {
          delay({ taskitemIds: JSON.stringify(this.selectedTaskItemIds) }).then(res => {
            if (res && res.errcode === 0) {
              this.selectedTaskItemIds = []
              this.initplandetailPage()
              this.$Notice.success({ desc: '操作成功' })
            }
          })
        }
      })
    }
  }
}
</script>
